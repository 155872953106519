import React from 'react';

const Tags = ({ tags, isTuvaJrApp, activeContentTypes }) => {
  return tags?.length > 0 ? (
    <div className="content-block_extrainfo tags d-flex mt-3 flex-items-start">
      <span className="ti ti-tag p3 text-gray lh-condensed-ultra mr-2 mt-1"></span>
      <div className="tags_wrapper overflow-hidden">
        {tags.map((tag, i) => (
          <a
            target="_blank"
            href={
              (isTuvaJrApp ? '/tuvajr' : '') +
              '/search/?q=' +
              tag +
              (isTuvaJrApp ? '&filter_type=jr' : '') +
              (activeContentTypes?.length > 0
                ? `&type=${activeContentTypes.toString()}`
                : '')
            }
            rel="noreferrer"
            className="mr-2 no-wrap d-inline-block"
            key={i}
          >
            {tag}
          </a>
        ))}
      </div>
    </div>
  ) : null;
};

export default Tags;
