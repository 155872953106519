import React, { Component } from 'react';
import Slider from 'react-slick';
import { ContentBlockV2 } from '@tuva-ui/components';

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className={`${
        onClick === null ? 'disabled' : ''
      } d-flex cursor-pointer flex-items-center flex-justify-center text-center slick-arrow ti ti-arrow-right`}
      style={{
        ...style,
        display: 'inline-block',
        background: 'white',
        filter: onClick === null ? 'opacity(0.7)' : 'none',
        cursor: onClick === null ? 'default' : 'pointer',
      }}
      onClick={onClick}
    ></div>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className={`${
        onClick === null ? 'disabled' : ''
      } d-flex cursor-pointer flex-items-center flex-justify-center text-center slick-arrow ti ti-arrow-left`}
      style={{
        ...style,
        display: 'inline-block',
        filter: onClick === null ? 'opacity(0.7)' : 'none',
        background: 'white',
        cursor: onClick === null ? 'default' : 'pointer',
      }}
      onClick={onClick}
    ></div>
  );
}

export default class SlickSlider extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  customNavigationChange = () => {
    setTimeout(() => {
      // Todo: Neeed alternative solution

      let isAlreadyUpdated = document.querySelector(
        `#slide_${this.props.index} .custom-slide-control`,
      );

      if (isAlreadyUpdated) {
        return 0;
      }

      let dots = document.querySelector(
        `#slide_${this.props.index} .slick-dots`,
      );
      let nextArrow = document.querySelector(
        `#slide_${this.props.index} .ti-arrow-right`,
      );
      let prevArrow = document.querySelector(
        `#slide_${this.props.index} .ti-arrow-left`,
      );
      if (nextArrow) {
        let slideControlView = document.createElement('div');
        slideControlView.className =
          'custom-slide-control d-flex position-relative';
        slideControlView.appendChild(prevArrow);
        slideControlView.appendChild(dots);
        slideControlView.appendChild(nextArrow);
        var s = document.querySelector(
          `#slide_${this.props.index} .slick-slider`,
        );
        s.appendChild(slideControlView);
      }
    }, 0);
  };

  componentDidMount() {
    this.customNavigationChange();
  }

  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      centerPadding: '20px',
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      onReInit: () => {
        this.customNavigationChange();
      },
      responsive: [
        {
          breakpoint: 599,
          settings: { slidesToShow: 1, slidesToScroll: 1 },
        },
        {
          breakpoint: 960,
          settings: { slidesToShow: 2, slidesToScroll: 2 },
        },
        {
          breakpoint: 1011,
          settings: { slidesToShow: 3, slidesToScroll: 3 },
        },
        {
          breakpoint: 1259,
          settings: { slidesToShow: 2, slidesToScroll: 2 },
        },
        {
          breakpoint: 1560,
          settings: { slidesToShow: 3, slidesToScroll: 3 },
        },
      ],
    };

    let {
      index,
      _filter,
      filter_type,
      results,
      ContentsApi,
      appConf,
      show,
      PAGE_TYPES,
      user,
      bookmarkCallback,
    } = this.props;

    return (
      <div className="mb-3" id={`slide_${index}`}>
        {/* TODO: Move this to useRER later */}
        <Slider id={`#${_filter.name}`} key={index} {...settings}>
          {results
            .filter(result => result.filter.name === _filter.name)
            .map(result => (
              <ContentBlockV2
                iconOnlyMode={true}
                data={result}
                user={user}
                ContentsApi={ContentsApi}
                bookmarkCallback={count => bookmarkCallback(count)}
                key={index}
                isSubdomain={this.props.isSubdomain}
                isBookmarkType={
                  filter_type === PAGE_TYPES.bookmarks.filter_type
                }
                isDoctrina={appConf.isDoctrina}
                isTuvaJrApp={this.props.isTuvaJrApp}
                isFromContentLibrary={true}
                show={show}
                isSlickSliderView={true}
              />
            ))}
        </Slider>
      </div>
    );
  }
}
