import React from 'react';

const TeacherGuide = ({ is_premium, isUserPremium, refDoc }) => {
  return (
    <span className="content_subtype mr-4 p3 text-gray d-flex flex-items-center">
      {!is_premium || (is_premium && isUserPremium) ? (
        <a target="_blank" href={refDoc} rel="noreferrer">
          <i className="ti ti-pdf mr-1 pdf-icon"></i> Tuva Dataset Guide
        </a>
      ) : (
        <span>
          <i className="ti ti-pdf mr-1 pdf-icon"></i> Tuva Dataset Guide
        </span>
      )}
    </span>
  );
};

export default TeacherGuide;
