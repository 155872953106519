import React from 'react';

const Steps = ({
  object_type,
  datasetNoOfActivities,
  noOfSteps,
  isFromMiniBlock,
}) => {
  return (
    <span
      className={`activity_steps ${
        isFromMiniBlock ? 'mr-2' : 'mr-4'
      } p3 text-gray d-flex flex-items-center`}
    >
      {object_type === 'dataset' && datasetNoOfActivities > 0 && (
        <>
          <span className="ti ti-steps p3 text-gray lh-condensed-ultra ml-1 mr-2"></span>
          {`${datasetNoOfActivities}`}{' '}
          {`${datasetNoOfActivities > 1 ? ' Activities' : ' Activity'}`}
        </>
      )}
      {noOfSteps > 0 && (
        <>
          <span className="ti ti-steps-2 p3 text-gray lh-condensed-ultra ml-1 mr-2"></span>
          {`${noOfSteps}`} {`${noOfSteps > 1 ? ' Steps' : ' Step'}`}
        </>
      )}
    </span>
  );
};

export default Steps;
