import React from 'react';
import PropTypes from 'prop-types';

const Attributes = ({ count, isFromMiniBlock }) => {
  return (
    <span
      className={`content_subtype p2 ${
        isFromMiniBlock ? 'mr-2' : 'mr-4'
      } m-0 text-gray d-flex flex-items-center`}
    >
      <i
        className={`ti ti-attributes ${
          isFromMiniBlock ? 'mr-1' : 'mr-2'
        }  d-inline-flex`}
      ></i>
      {count} Attributes
    </span>
  );
};

Attributes.propTypes = {
  count: PropTypes.number,
};

export default Attributes;
