import React from 'react';
import Button from '../../Button/Button';

const BookmarkButton = ({
  bookmarkId,
  toggleBookmark,
  isBookmarking,
  className,
  iconOnlyMode,
  isFromMathLib,
  innerClassName,
}) => {
  return (
    <Button
      className={`content_bookmark btn d-flex flex-items-center flex-justify-center ${
        bookmarkId !== 0 ? 'btn-secondary' : 'btn-outline'
      } ${className || ''}`}
      onClick={e => toggleBookmark()}
      disabled={isBookmarking}
    >
      <span
        className={`ti p3 text-green lh-condensed-ultra ${
          !isFromMathLib ? 'mt-1' : ''
        } ${bookmarkId !== 0 ? 'ti-bookmark' : 'ti-bookmark-outline'}`}
      ></span>
      <span
        className={`ml-2 ${innerClassName} ${iconOnlyMode ? 'd-none d-xl-block ml-0' : ''} ${
          isFromMathLib ? 'd-none' : ''
        }`}
      >
        {bookmarkId !== 0 ? 'Bookmarked' : 'Bookmark'}
      </span>
    </Button>
  );
};

export default BookmarkButton;
