import React from 'react';
import PropTypes from 'prop-types';

const CaseCount = ({ count, isFromMiniBlock }) => {
  return (
    <span
      className={`content_subtype p2 ${
        isFromMiniBlock ? 'mr-2' : 'mr-4'
      } m-0 text-gray d-flex flex-items-center`}
    >
      <i className={`ti ti-cases ${
        isFromMiniBlock ? 'mr-1' : 'mr-2'
      } d-inline-flex`}></i>
      {count} Cases
    </span>
  );
};

CaseCount.propTypes = {
  count: PropTypes.number,
};

export default CaseCount;
