import React from 'react';

const ContentImg = ({
  className,
  access_url,
  title,
  get_image,
  isNew,
  isRevised,
  isArchived,
  isFromMathLib,
}) => {
  return (
    <div
      className={`${className} ${
        isFromMathLib ? ' ' : ' d-md-block d-none'
      } bg-gray-3 content-block_image position-relative`}
    >
      <a target="_self" href={access_url} title={title}>
        {get_image !== 'None' && !!get_image && (
          <img src={get_image} alt={title} />
        )}
        {isArchived ? (
          <span className="archived_tag s0">Archived</span>
        ) : isNew ? (
          <span className="new_ribbon_tag s0">New</span>
        ) : isRevised ? (
          <span className="new_ribbon_tag s0">Updated</span>
        ) : null}
      </a>
    </div>
  );
};

export default ContentImg;
