import React, { useEffect } from 'react';
import { ToolTip } from '@tuva-ui/components/';

const ContentType = ({
  contentTypeLabel,
  contentTypeIcon,
  is_premium,
  isUserPremium,
  isUserJunior,
  isDoctrina,
  user,
  hasAnswerkey,
  isCollectionBlock,
  isFromMathLib,
  isTuvaJrApp,
}) => {
  useEffect(() => {
    ToolTip.rebuild();
  }, [contentTypeLabel]);

  return (
    <div className="content-block_type_type d-flex flex-items-center">
      <p className="p2 m-0 text-gray d-flex flex-items-center">
        {/* <img src={contentTypeIcon} alt="content_icon" className="mr-2" /> */}
        <span className={`ti ${contentTypeIcon} mr-2 d-inline-flex`}></span>
        {contentTypeLabel}
      </p>
      {!(isDoctrina || isCollectionBlock) &&
        (is_premium ? (
          <>
            {isTuvaJrApp ? (
              <span
                className="d-flex label label--premium py-1 px-1 p2 ml-2"
                data-tip={'Accessible for Tuva Jr. Subscribers Only'}
              >
                <i
                  className={`ti p3 text-white lh-condensed-ultra ${
                    isUserJunior ? '' : 'ti-lock mr-1'
                  }`}
                ></i>
                Tuva Jr.
              </span>
            ) : (
              <span
                className={` ${
                  isTuvaJrApp || isFromMathLib ? 'ml-2' : 'ml-3'
                } d-flex label label--premium py-1 px-2 p2`}
                data-tip={'Accessible for Tuva Premium Subscribers Only'}
              >
                <i
                  className={`ti p3 text-white lh-condensed-ultra ${
                    isUserPremium ? '' : 'ti-lock mr-1'
                  }`}
                ></i>
                Premium
              </span>
            )}
          </>
        ) : (
          <span className="label label--free py-1 px-2 p2 d-flex flex-items-center p2 ml-3 border-0">
            Free
          </span>
        ))}
      {(!user || (user && user.type === 'teacher')) &&
        !isCollectionBlock &&
        hasAnswerkey && (
          <div
            className={`label--premium px-2 p3 d-flex flex-items-center ${
              isTuvaJrApp || isFromMathLib ? 'ml-2' : 'ml-3'
            } border text-black bg-yellow-1`}
            data-tip={`${
              user &&
              (isTuvaJrApp ? !isUserJunior : !isUserPremium && is_premium)
                ? `Upgrade to Tuva ${
                    isTuvaJrApp ? 'Jr.' : 'Premium'
                  } to Access Solutions`
                : 'Try as Student to view possible answers.'
            }`}
            style={{ minHeight: '24px' }}
          >
            <span
              className={`${
                !isFromMathLib ? 'pr-1 ' : ''
              }ti p3 lh-condensed-ultra ti ti-bulb`}
            ></span>
            {!isFromMathLib && 'Solutions'}
          </div>
        )}
    </div>
  );
};

export default ContentType;
